import { Icon, Typography } from '@ohif/ui';
import React from 'react';

interface Props {
  onClose: () => void;
}

const RejectModal: React.FC<Props> = ({ onClose }) => {
  return (
    <div>
      <div className="bg-secondary-dark p-5 flex justify-between items-center">
        <Typography variant="h5">Error uploading</Typography>
        <Icon
          name="close"
          className="w-[20px] h-[20px] cursor-pointer"
          onClick={e => {
            onClose();
          }}
        />
      </div>
      <Typography variant="body" className={'p-5 text-[15px]'}>
        Invalid upload. Only {'.dcm'} files are allowed.
      </Typography>
    </div>
  );
};

export default RejectModal;
