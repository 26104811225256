import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class UsersApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getMe(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `users/me`
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCrossBorderAgency(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/institutions/cross-border-agency`
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getRequestingPhysicians(filterValues: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `users/requesting-physicians`,
        {
          city: filterValues.city === '' ? undefined : filterValues.city,
          country:
            filterValues.country === '' ? undefined : filterValues.country,
          licensedOnly:
            filterValues.licensedOnly === ''
              ? undefined
              : filterValues.licensedOnly,
          icp: filterValues.icp === '' ? undefined : filterValues.icp,
          icz: filterValues.icz === '' ? undefined : filterValues.icz,
          name: filterValues.name === '' ? undefined : filterValues.name,
          sortBy: filterValues.sortBy === '' ? undefined : filterValues.sortBy,
          sortDirection:
            filterValues.sortDirection === ''
              ? undefined
              : filterValues.sortDirection,
          pageNumber:
            filterValues.pageNumber === ''
              ? undefined
              : filterValues.pageNumber,
          resultsPerPage:
            filterValues.resultsPerPage === ''
              ? undefined
              : filterValues.resultsPerPage,
        }
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async assignStudyWithoutLicense(
    medicalEstablishmentBranch: string
  ): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/users/physicians/${medicalEstablishmentBranch}`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getLastTenRequestingPhysicians(physiciansIds: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `users/requesting-physicians`,
        physiciansIds
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  async getRequestingPhysiciansCount(filterValues: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `users/requesting-physicians/count`,
        {
          city: filterValues.city === '' ? undefined : filterValues.city,
          country:
            filterValues.country === '' ? undefined : filterValues.country,
          licensedOnly:
            filterValues.licensedOnly === ''
              ? undefined
              : filterValues.licensedOnly,
          icp: filterValues.icp === '' ? undefined : filterValues.icp,
          icz: filterValues.icz === '' ? undefined : filterValues.icz,
          name: filterValues.name === '' ? undefined : filterValues.name,
          sortBy: filterValues.sortBy === '' ? undefined : filterValues.sortBy,
          sortDirection:
            filterValues.sortDirection === ''
              ? undefined
              : filterValues.sortDirection,
        }
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
