import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ExportDiagnosisModal, useImageViewer } from '@ohif/ui';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocuPDF from './DocuPDF';
import { DiagnosisItem, useSessionConfiguration } from '@ohif/ui';
import moment from 'moment';

export default function PanelDiagnosisExport({ servicesManager }) {
  const {
    CarianApiService,
    UserAuthenticationService,
    UIModalService,
  } = servicesManager.services;
  const { StudyInternalUIDs } = useImageViewer();

  const [diagnosisList, setDiagnosisList] = useState([]);
  const [{ layout: checked }, api] = useSessionConfiguration();

  const [draft, setDraft] = useState();
  const [isLoading, setIsloading] = useState(false);
  const getData = async () => {
    setIsloading(true);
    CarianApiService.api.setClinic(UserAuthenticationService.getClinic());
    CarianApiService.api.setToken(UserAuthenticationService.getToken());
    CarianApiService.UserAuthenticationService;
    const result = await CarianApiService.diagnosis.list(StudyInternalUIDs);
    setDiagnosisList(result);
    setIsloading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const [exportContent, setExportContent] = useState([]);

  const dowloadPDF = async () => {
    const diagnosisData = await exportContent[0];

    UIModalService.show({
      layout: checked,
      content: ExportDiagnosisModal,
      customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
      contentProps: {
        servicesManager,
        diagnosisData,
        onClose: () => {
          UIModalService.hide();
        },
      },
    });
  };

  return (
    <div>
      {JSON.stringify(exportContent) === JSON.stringify([]) ? (
        <div className="py-2 width-full text-white flex justify-center">
          Select to Export
        </div>
      ) : (
        <div className="flex flex-col justify-between mt-1">
          <Button
            onClick={() => {
              dowloadPDF();
            }}
          >
            Export
          </Button>
        </div>
      )}

      <div className="mt-2">
        {diagnosisList.map(item => (
          <DiagnosisItem
            user={''}
            createdAt={item?.createdAt ?? ''}
            studyId={item?.study?.dicomId ?? ''}
            madeBy={
              item?.physician?.user?.lastName ??
              '' + ' ' + item?.physician?.user?.firstName ??
              '' ??
              ''
            }
            key={item?.id}
            id={item?.id}
            patientName={
              item?.study.patient?.user?.lastName ??
              '' + ' ' + item?.study.patient?.user?.firstName ??
              ''
            }
            patientId={item?.study?.patient?.documentNumber ?? ''}
            patientBirthDate={item?.study?.patient?.birthDate ?? ''}
            studyDescription={item?.study?.description ?? ''}
            studyDate={item?.study?.date ?? ''}
            exportsDiagnosis={true}
            diagnosisIndex={diagnosisList.indexOf(item)}
            setExportContent={setExportContent ?? ''}
            exportContentState={exportContent ?? ''}
            diagnosis={item ?? ''}
            label={`${moment(item.createdAt).format('DD-MM-YYYY') ?? ''}` ?? ''}
            institutionName={item?.study?.physician?.clinicName ?? ''}
            description={item?.text ?? ''}
            contact={item?.study?.clinic?.phoneNumber ?? ''}
            referringPhysician={
              item.study?.physician?.user?.lastName ??
              '' + ' ' + item.study?.physician?.user?.firstName ??
              ''
            }
            IDNo={item?.study?.clinic?.medicalEstablishment ?? ''}
            labName={item?.study.clinic.name ?? ''}
          />
        ))}
      </div>
    </div>
  );
}

PanelDiagnosisExport.propTypes = {
  commandsManager: PropTypes.shape({
    runCommand: PropTypes.func.isRequired,
  }),
  servicesManager: PropTypes.shape({
    services: PropTypes.shape({}).isRequired,
  }).isRequired,
};
