import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@ohif/ui';
import './scrollbar.css';
import LastRequestingPhysiciansItem from './LastRequestingPhysiciansItem';
import PhysiciansFilter from './PhysiciansFilter';
interface Props {
  servicesManager: any;
  physicianDicomId: string;

  id: string;
  onClose: () => void;
  apiLastPhysicians: any;
  lastTenPhysicians?: [];
}

const PhysicianItem = ({
  id,
  itemId,
  medicalEstablishment,
  medicalEstablishmentBranch,
  lastName,
  firstName,
  city,
  country,
  assignStudy,
  addToLastRequestingPhysicians,
}: any) => {
  return (
    <>
      <div
        key={itemId}
        className="w-full flex justify-between border-white border items-center my-5 p-2 rounded-lg"
      >
        <div>
          <div className="flex flex-wrap">
            <h5 className="max-w-[150px] truncate">
              IČZ: {medicalEstablishment ?? '... '}
            </h5>
            <span className="mx-2">|</span>
            <h5 className="max-w-[150px] truncate">
              IČP: {medicalEstablishmentBranch ?? '... '}
            </h5>
            <span className="mx-2">|</span>
            <h5 className="max-w-[200px] truncate">
              {lastName ?? ''}
              {(lastName || firstName) && ', '}
              {firstName ?? ''}
            </h5>
            <span className="mx-2">|</span>
            <h5 className="max-w-[200px] truncate">
              {city ?? ''}
              {(city || country) && ', '}
              {country ?? ''}
            </h5>
          </div>
        </div>
        <Button
          onClick={() => {
            assignStudy(id, itemId, medicalEstablishmentBranch);
            addToLastRequestingPhysicians(itemId);
          }}
        >
          Assign
        </Button>
      </div>
    </>
  );
};

const PhysicianPagination = ({
  physicians,
  filterValuesForPagination,
  setFilterValuesForPagination,
  licensedOnly,
}: any) => {
  return (
    <>
      <div className="flex w-full justify-end items-center">
        <p className="m-0 leading-tight text-base text-white opacity-60 mr-4 text-base">
          Page {filterValuesForPagination.pageNumber}
        </p>
        <div role="group" className="group flex-row inline-flex">
          <button
            className="leading-none font-sans text-center justify-center items-center outline-none transition duration-300 ease-in-out focus:outline-none text-white hover:opacity-80 active:opacity-100 focus:opacity-80 undefined border border-primary-main inline-flex border outline-none border border-r-0 last:border-r first:rounded-l-md last:rounded-r-md py-2 px-2 text-base"
            type="button"
            onClick={() => {
              filterValuesForPagination.pageNumber > 1 &&
                setFilterValuesForPagination({
                  ...filterValuesForPagination,
                  licensedOnly: licensedOnly ?? '',
                  pageNumber: filterValuesForPagination.pageNumber - 1,
                });
            }}
            data-cy="undefined-btn"
          >
            &lt; Previous
          </button>
          <button
            className={`${physicians.length < 10 &&
              'opacity-60'} ${physicians.length >= 10 &&
              'hover:opacity-80 active:opacity-100 focus:opacity-80'} leading-none font-sans text-center justify-center items-center outline-none transition duration-300 ease-in-out focus:outline-none text-white  undefined border border-primary-main inline-flex border outline-none border border-r-0 last:border-r first:rounded-l-md last:rounded-r-md py-2 px-4 text-base`}
            type="button"
            data-cy="undefined-btn"
            onClick={() =>
              physicians.length >= 10 &&
              setFilterValuesForPagination({
                ...filterValuesForPagination,
                licensedOnly: licensedOnly ?? '',
                pageNumber: filterValuesForPagination.pageNumber + 1,
              })
            }
          >
            Next &gt;
          </button>
        </div>
      </div>
    </>
  );
};

const GetRequestingPhysiciansModal: React.FC<Props> = ({
  servicesManager,
  id,
  onClose,
  apiLastPhysicians,
  lastTenPhysicians,
  physicianDicomId,
}) => {
  const { CarianApiService } = servicesManager.services;
  const [requestingPhysicians, setRequestingPhysicians] = useState<any>([]);
  const [
    requestingPhysiciansWithlicensed,
    setRequestingPhysiciansWithlicensed,
  ] = useState<any>([]);
  const [countRequestingPhysicians, setCountRequestingPhysicians] = useState();
  const [
    countRequestingPhysiciansWithLicense,
    setCountRequestingPhysiciansWithLicense,
  ] = useState();
  const [lastTenPhysiciansState, setLastRequestingPhysiciansState] = useState(
    []
  );

  const [filterValues, _setFilterValues] = useState({
    ...defaultFilterValues,
  });

  const [filterValuesWithLicense, _setFilterValuesWithLicense] = useState({
    ...defaultFilterValues,
    licensedOnly: true,
  });

  const setFilterValues = val => {
    if (filterValues.pageNumber === val.pageNumber) {
      val.pageNumber = 1;
    }
    _setFilterValues(val);
  };

  const setFilterValuesWithLicense = val => {
    if (filterValuesWithLicense.pageNumber === val.pageNumber) {
      val.pageNumber = 1;
    }
    _setFilterValuesWithLicense(val);
  };

  const [loading, setLoading] = useState<boolean>(true);
  const getRequestingPhysicians = async () => {
    try {
      const res = await CarianApiService.users.getRequestingPhysicians(
        filterValues
      );

      const resWithLicense = await CarianApiService.users.getRequestingPhysicians(
        {
          ...filterValues,
          licensedOnly: true,
          pageNumber: filterValuesWithLicense.pageNumber,
        }
      );

      const countRequestingPhysicians = await CarianApiService.users.getRequestingPhysiciansCount(
        filterValues
      );

      const countRequestingPhysiciansWithLicense = await CarianApiService.users.getRequestingPhysiciansCount(
        {
          ...filterValues,
          licensedOnly: true,
          pageNumber: filterValuesWithLicense.pageNumber,
        }
      );

      if (lastTenPhysicians.length > 0) {
        const resLastPhysicians = await CarianApiService.users.getLastTenRequestingPhysicians(
          lastTenPhysicians
        );
        await setLastRequestingPhysiciansState(resLastPhysicians);
      }
      await setCountRequestingPhysicians(countRequestingPhysicians);
      await setCountRequestingPhysiciansWithLicense(
        countRequestingPhysiciansWithLicense
      );
      await setRequestingPhysicians(res);
      await setRequestingPhysiciansWithlicensed(resWithLicense);
      await setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const addToLastRequestingPhysicians = async (physicianId: string) => {
    await apiLastPhysicians.updateLastTenPhysicians(physicianId);
  };

  const assignStudy = async (
    id: string,
    physicianId: string,
    medicalEstablishmentBranch?: string
  ) => {
    try {
      if (physicianId) {
        await CarianApiService.study.assignStudy(id, {
          physicianId: physicianId,
        });
      } else {
        const res = await CarianApiService.users.assignStudyWithoutLicense(
          medicalEstablishmentBranch
        );
        await CarianApiService.study.assignStudy(id, {
          physicianId: res.id,
        });
      }
      const params = new URLSearchParams(window.location.search);

      const queryParams = {};
      for (const param of params.entries()) {
        queryParams[param[0]] = param[1];
      }

      window.location.reload();

      const newParams = new URLSearchParams(queryParams).toString();
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${newParams}`
      );
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRequestingPhysicians();
  }, []);

  useEffect(() => {
    getRequestingPhysicians();
  }, [filterValues, filterValuesWithLicense]);

  return (
    <div>
      <div>
        {loading ? (
          <div className="w-full flex justify-center items-center py-20">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-[#789ab7] border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          <>
            {lastTenPhysicians?.length > 0 && (
              <>
                <h2>Last Physicians Assigned:</h2>
                <div className="mb-5 overflow-x-scroll scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-900">
                  <div className="flex">
                    {lastTenPhysiciansState?.map((item: any) => (
                      <LastRequestingPhysiciansItem
                        key={item.id}
                        item={item}
                        id={id}
                        handleAssing={assignStudy}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className="flex items-center">
              <PhysiciansFilter
                filterValues={filterValues}
                onChange={setFilterValues}
              />
              <div className="w-[161px] ml-[-70px] ">
                <h2 className="text-xl font-bold">Dicom Tag:</h2>
                <span className="text-primary-light text-xl font-bold">
                  {physicianDicomId}
                </span>
              </div>
            </div>

            <div>
              <div className="flex w-full items-center justify-between mt-2">
                <h2 className="text-xl font-bold">
                  Requesting Physicians with a Carian License:
                </h2>
                <h2 className="text-xl font-bold">
                  <span className="text-primary-light">
                    {countRequestingPhysiciansWithLicense
                      ? countRequestingPhysiciansWithLicense?.count
                      : '0'}{' '}
                  </span>
                  Physicians
                </h2>
              </div>
            </div>

            {requestingPhysiciansWithlicensed?.map((item: any) => {
              return (
                <>
                  <PhysicianItem
                    key={item.id}
                    itemId={item.id}
                    id={id}
                    medicalEstablishment={item?.medicalEstablishment}
                    medicalEstablishmentBranch={
                      item?.medicalEstablishmentBranch
                    }
                    lastName={item?.lastName}
                    firstName={item?.firstName}
                    city={item?.city}
                    country={item?.country}
                    assignStudy={assignStudy}
                    addToLastRequestingPhysicians={
                      addToLastRequestingPhysicians
                    }
                  />
                </>
              );
            })}

            <PhysicianPagination
              physicians={requestingPhysiciansWithlicensed}
              filterValuesForPagination={filterValuesWithLicense}
              setFilterValuesForPagination={setFilterValuesWithLicense}
              licensedOnly={true}
            />

            {/* <div className="flex w-full justify-end items-center">
              <p className="m-0 leading-tight text-base text-white opacity-60 mr-4 text-base">
                Page {filterValuesWithLicense.pageNumber}
              </p>
              <div role="group" className="group flex-row inline-flex">
                <button
                  className="leading-none font-sans text-center justify-center items-center outline-none transition duration-300 ease-in-out focus:outline-none text-white hover:opacity-80 active:opacity-100 focus:opacity-80 undefined border border-primary-main inline-flex border outline-none border border-r-0 last:border-r first:rounded-l-md last:rounded-r-md py-2 px-2 text-base"
                  type="button"
                  onClick={() => {
                    filterValuesWithLicense.pageNumber > 1 &&
                      setFilterValuesWithLicense({
                        ...filterValuesWithLicense,
                        licensedOnly: true,
                        pageNumber: filterValuesWithLicense.pageNumber - 1,
                      });
                  }}
                  data-cy="undefined-btn"
                >
                  &lt; Previous
                </button>
                <button
                  className="leading-none font-sans text-center justify-center items-center outline-none transition duration-300 ease-in-out focus:outline-none text-white hover:opacity-80 active:opacity-100 focus:opacity-80 undefined border border-primary-main inline-flex border outline-none border border-r-0 last:border-r first:rounded-l-md last:rounded-r-md py-2 px-4 text-base"
                  type="button"
                  data-cy="undefined-btn"
                  onClick={() =>
                    requestingPhysiciansWithlicensed.length >= 10 &&
                    setFilterValuesWithLicense({
                      ...filterValuesWithLicense,
                      licensedOnly: true,
                      pageNumber: filterValuesWithLicense.pageNumber + 1,
                    })
                  }
                >
                  Next &gt;
                </button>
              </div>
            </div> */}

            <div className="flex w-full items-center justify-between mt-10">
              <h2 className="text-xl font-bold">
                Requesting Physicians without a Carian License:
              </h2>
              <h2 className="text-xl font-bold">
                <span className="text-primary-light">
                  {countRequestingPhysicians
                    ? countRequestingPhysicians?.count
                    : '0'}{' '}
                </span>
                Physicians
              </h2>
            </div>

            {requestingPhysicians?.map((item: any) => {
              return (
                <PhysicianItem
                  key={item.id}
                  itemId={item.id}
                  id={id}
                  medicalEstablishment={item?.medicalEstablishment}
                  medicalEstablishmentBranch={item?.medicalEstablishmentBranch}
                  lastName={item?.lastName}
                  firstName={item?.firstName}
                  city={item?.city}
                  country={item?.country}
                  assignStudy={assignStudy}
                  addToLastRequestingPhysicians={addToLastRequestingPhysicians}
                />
              );
            })}

            <div className="flex w-full justify-end items-center">
              {/* <p className="m-0 leading-tight text-base text-white opacity-60 mr-4 text-base">
                Page {filterValues.pageNumber}
              </p> */}

              <PhysicianPagination
                physicians={requestingPhysicians}
                filterValuesForPagination={filterValues}
                setFilterValuesForPagination={setFilterValues}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GetRequestingPhysiciansModal;

const defaultFilterValues = {
  city: undefined,
  licensedOnly: undefined,
  country: undefined,
  icp: undefined,
  icz: undefined,
  name: undefined,
  sortBy: undefined,
  sortDirection: undefined,
  resultsPerPage: 10,
  pageNumber: 1,
};
