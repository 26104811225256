import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';
import moment from 'moment';

export class DiagnosisApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async list(studyId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/${studyId}/diagnosis`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async latest(studyId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/${studyId}/diagnosis/latest`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async create(studyId: string, diagnosis: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/studies/study/${studyId}/diagnosis`,
        diagnosis
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getDraft(studyId: string, diagnosis: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/${studyId}/diagnosis/draft`,
        diagnosis
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        return null;
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createDraft(studyId: string, diagnosis: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/studies/study/${studyId}/diagnosis/draft`,
        diagnosis
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateDraft(studyId: string, diagnosis: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `/studies/study/${studyId}/diagnosis/draft`,
        diagnosis
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async finalizeDraft(studyId: string, diagnosis: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `/studies/study/${studyId}/diagnosis/draft/finalize`,
        diagnosis
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  async discardDraft(studyId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `/studies/study/${studyId}/diagnosis/draft/discard`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async addPatientStudy(
    userData: any,
    dcmFile: any,
    cbhaSelectedId: string
  ): Promise<any> {
    try {
      const formData = new FormData();

      // Construct the data object
      const dataObject = {
        PatientID: userData.roleEntity.documentNumber,
        TypeOfPatientID: userData.roleEntity.documentTypeId?.toString() || '',
        PatientName: `${userData.user.lastName ?? ''}^${userData.user
          .firstName ?? ''}`,
        PatientAddress: `${userData.roleEntity.street ?? ''}^${userData
          .roleEntity.city ?? ''}^${userData.roleEntity.zipCode ??
          ''}^${userData.roleEntity.country ?? ''}`,
        PatientBirthDate: userData.roleEntity.birthDate
          ? moment(userData.roleEntity.birthDate).format('YYYYMMDD')
          : '',
        ResponsibleOrganization: cbhaSelectedId,
      };

      // Convert the data object to a string and append it to the form data
      formData.append('data', JSON.stringify(dataObject));

      // Append the .dcm file to the form data
      formData.append('file', dcmFile);

      // Make the API call with form data
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/ingest/uploaddcm',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Handle API response
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
