import React from 'react';
import PropTypes from 'prop-types';
import {
  OneTimeAccessModal,
  PurchaseLicenseModal,
  useImageViewer,
  useSessionConfiguration
} from '@ohif/ui';

export default function PanelDiagnosisExport({ servicesManager }) {
  const {
    CarianApiService,
    UserAuthenticationService,
    UIModalService,
  } = servicesManager.services;
  const { StudyInternalUIDs } = useImageViewer();
  const [{ layout: checked }, api: apiLayout] = useSessionConfiguration();

  const createCode = async () => {
    const oneTimeData = await CarianApiService.oneTimeAccess.createCode(
      StudyInternalUIDs[0]
    );
    UIModalService.show({
      layout: checked,
      content: OneTimeAccessModal,
      customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px]`,
      contentProps: {
        servicesManager,
        StudyInternalUIDs,
        oneTimeData,
        onClose: () => {
          UIModalService.hide();
        },
      },
    });
  };

  const tokenData = UserAuthenticationService.getTokenData();
  const userRole = tokenData.roleId
    ? tokenData.roleId
    : tokenData.userData.roleId;

  return (
    <div className="flex flex-col">
      {userRole == 6 && (
        <button
          onClick={() =>
            UIModalService.show({
              layout: checked,
              content: PurchaseLicenseModal,
              customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
              contentProps: {
                onClose: () => {
                  UIModalService.hide();
                },
                servicesManager,
              },
            })
          }
          className="ml-2 px-2  h-8  items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
        >
          Generate OneTimeAccess
        </button>
      )}

      {userRole == 1 && (
        <button
          onClick={() => {
            createCode();
          }}
          className="ml-2 px-2 h-8 items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
        >
          Generate OneTimeAccess
        </button>
      )}

      {userRole == 2 && (
        <button
          onClick={() => {
            createCode();
          }}
          className="ml-2 px-2 h-8 items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
        >
          Generate OneTimeAccess
        </button>
      )}

      {userRole == 3 && (
        <button
          onClick={() => {
            createCode();
          }}
          className="ml-2 px-2 h-8 items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
        >
          Generate OneTimeAccess
        </button>
      )}

      {userRole == 4 && (
        <button
          onClick={() => {
            createCode();
          }}
          className="ml-2 px-2 h-8 items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
        >
          Generate OneTimeAccess
        </button>
      )}

      {userRole ==  5 && null}

      {userRole == 7 && (
        <button
          onClick={() => {
            createCode();
          }}
          className="ml-2 px-2 h-8 items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
        >
          Generate OneTimeAccess
        </button>
      )}


    </div>
  );
}

PanelDiagnosisExport.propTypes = {
  commandsManager: PropTypes.shape({
    runCommand: PropTypes.func.isRequired,
  }),
  servicesManager: PropTypes.shape({
    services: PropTypes.shape({}).isRequired,
  }).isRequired,
};
