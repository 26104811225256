import { Button, Icon, Typography } from '@ohif/ui';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Props {
  apiService: any;
  onClose: () => void;
  files: [];
}

interface cbhaItem {
  id: string;
  name: string;
  dicomId: string;
}

interface DropDownProps {
  setCbhaSelected: any;
  setCbhaSelectedId: any;
  items: cbhaItem[];
  cbhaSelected: string;
}
const DropDown: React.FC<DropDownProps> = ({
  items,
  setCbhaSelected,
  cbhaSelected,
  setCbhaSelectedId,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <div className="mt-5">
      <Button
        onClick={toggleDropDown}
        id="dropdownDefaultButton"
        data-dropdown-toggle="dropdown"
        className="text-white w-[350px] bg-secondary-dark focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        <Typography variant="body" className="text-white text-[15px]">
          {cbhaSelected === '' ? 'Select CBHA' : cbhaSelected}
        </Typography>
        <svg
          className="w-2.5 h-2.5 ml-2.5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </Button>

      <div
        id="dropdown"
        className={`z-10 absolute ${
          showDropDown ? '' : 'hidden'
        } bg-white divide-y divide-gray-100 rounded-lg shadow w-[350px] dark:bg-gray-700 mt-2`}
      >
        <ul
          className="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          {items.map(item => (
            <li
              key={item?.id}
              onClick={() => {
                toggleDropDown();
                setCbhaSelected(item?.name);
                setCbhaSelectedId(item?.dicomId);
              }}
              className="cursor-pointer"
            >
              <p className="block px-4 py-2 text-[14px] hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                {item?.name}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const UploadModal: React.FC<Props> = ({ apiService, onClose, files }) => {
  const [cbhaList, setCbhaList] = useState([]);
  const [cbhaSelected, setCbhaSelected] = useState('');
  const [cbhaSelectedId, setCbhaSelectedId] = useState('');
  const [userData, setUserData] = useState<any>();

  const getData = async () => {
    const cbhaData = await apiService.users.getCrossBorderAgency();
    const userData = await apiService.users.getMe();
    setUserData(userData);
    setCbhaList(cbhaData);
  };

  const handleSumbit = async () => {
    for (const file of files) {
      await apiService.diagnosis.addPatientStudy(
        userData,
        file,
        cbhaSelectedId
      );
    }
    onClose();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="bg-secondary-dark p-5 flex justify-between items-center">
        <Typography variant="h5">Select CBHA to upload your study</Typography>
        <Icon
          name="close"
          className="w-[20px] h-[20px] cursor-pointer"
          onClick={e => {
            onClose();
          }}
        />
      </div>
      <DropDown
        items={cbhaList}
        setCbhaSelected={setCbhaSelected}
        setCbhaSelectedId={setCbhaSelectedId}
        cbhaSelected={cbhaSelected}
      />
      <div className="mt-32 w-full flex justify-end gap-3">
        <Button
          className="bg-common-light"
          onClick={e => {
            onClose();
          }}
        >
          Cancel
        </Button>
        {cbhaSelected === '' ? (
          <Button className="bg-common-light opacity-50 hover:bg-common-light hover:opacity-50">
            Accept
          </Button>
        ) : (
          <Button className="" onClick={() => handleSumbit()}>
            Accept
          </Button>
        )}
      </div>
    </div>
  );
};

export default UploadModal;
