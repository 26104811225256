/**
 * Manages all requests to the API.
 */

const Datasource = CarianApiService => {
  return {
    query: {
      studies: {
        search: (params: any) => {
          return CarianApiService.study.search({
            ...params,
            sortBy: params.sortBy ? params.sortBy : 'studyDate',
            sortDirection: params.sortDirection
              ? params.sortDirection
              : 'descending',
          });
        },
        count: (filterValues: any) => {
          return CarianApiService.study.getCountList({
            description:
              filterValues.description === ''
                ? undefined
                : filterValues.description,
            modalities:
              filterValues.modalities === ''
                ? undefined
                : filterValues.modalities,
            patientId:
              filterValues.patientId === ''
                ? undefined
                : filterValues.patientId,
            patientName:
              filterValues.patientName === ''
                ? undefined
                : filterValues.patientName,
            missingPhysician:
              filterValues.missingPhysician === ''
                ? undefined
                : filterValues.missingPhysician,
            startDate:
              filterValues.startDate === ''
                ? undefined
                : filterValues.startDate,
            endDate:
              filterValues.endDate === '' ? undefined : filterValues.endDate,
            status:
              filterValues.status === '' ? undefined : filterValues.status,
          });
        },
      },
      studiesMissing: {
        search: async (params: any) => {
          return await CarianApiService.study.search({
            ...params,
            missingPhysician: true,
            sortBy: params.sortBy ? params.sortBy : 'studyDate',
            sortDirection: params.sortDirection
              ? params.sortDirection
              : 'descending',
          });
        },
        count: (filterValues: any) => {
          return CarianApiService.study.getCountList({
            description:
              filterValues.description === ''
                ? undefined
                : filterValues.description,
            modalities:
              filterValues.modalities === ''
                ? undefined
                : filterValues.modalities,
            patientId:
              filterValues.patientId === ''
                ? undefined
                : filterValues.patientId,
            patientName:
              filterValues.patientName === ''
                ? undefined
                : filterValues.patientName,
            missingPhysician: true,
            startDate:
              filterValues.startDate === ''
                ? undefined
                : filterValues.startDate,
            endDate:
              filterValues.endDate === '' ? undefined : filterValues.endDate,
            status:
              filterValues.status === '' ? undefined : filterValues.status,
          });
        },
      },
      drafts: {
        search: (params: any) => {
          return CarianApiService.study.search({
            ...params,
            sortBy: params.sortBy ? params.sortBy : 'studyDate',
            sortDirection: params.sortDirection
              ? params.sortDirection
              : 'descending',
            status: ['Open'],
          });
        },
        count: (filterValues: any) => {
          return CarianApiService.study.getCountList({
            description:
              filterValues.description === ''
                ? undefined
                : filterValues.description,
            modalities:
              filterValues.modalities === ''
                ? undefined
                : filterValues.modalities,
            patientId:
              filterValues.patientId === ''
                ? undefined
                : filterValues.patientId,
            patientName:
              filterValues.patientName === ''
                ? undefined
                : filterValues.patientName,
            missingPhysician:
              filterValues.missingPhysician === ''
                ? undefined
                : filterValues.missingPhysician,
            startDate:
              filterValues.startDate === ''
                ? undefined
                : filterValues.startDate,
            endDate:
              filterValues.endDate === '' ? undefined : filterValues.endDate,
            status: ['Open'],
          });
        },
      },
      series: {
        search: (studyId: string) =>
          CarianApiService.study.getStudySeries(studyId),
      },
    },
    setToken: token => {
      CarianApiService.api.setToken(token);
    },

    setClinic: clinic => {
      CarianApiService.api.setClinic(clinic);
    },

    setCrossBorderAgencyId: crossBorderAgencyId => {
      CarianApiService.api.setCrossBorderAgencyId(crossBorderAgencyId);
    },
  };
};
export default Datasource;
