import {
  Header,
  SwitchButton,
  Typography,
  useSessionConfiguration,
} from '@ohif/ui';
import React from 'react';
import DropZone from './DropZone';

interface Props {}

const UploadStudy: React.FC<Props> = ({ servicesManager }) => {
  const [{ layout: checked }, api] = useSessionConfiguration();
  const { UIModalService, CarianApiService } = servicesManager.services;

  function handleToggle() {
    api.setLayoutToggle(!checked);
  }

  return (
    <>
      <Header
        isSticky
        menuOptions={[
          {
            title: 'Header:About',
            icon: 'info',
          },
        ]}
        isReturnEnabled={false}
        // WhiteLabeling={appConfig.whiteLabeling}
        swichButtonOn={
          <SwitchButton checked={checked} onClick={handleToggle} />
        }
      />
      <div
        className={`flex items-center ${
          checked ? 'justify-start' : 'justify-center'
        }`}
      >
        <div className="w-[800px] mt-12">
          <Typography variant={'h3'} className={'text-primary-light'}>
            Upload your study
          </Typography>
          <DropZone
            apiService={CarianApiService}
            servicesManager={servicesManager}
          />
        </div>
      </div>
    </>
  );
};

export default UploadStudy;
