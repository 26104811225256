import {
  DashBoardTitleBox,
  RectangleButton,
  RecommendCarianModal,
} from '@ohif/ui';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ChangeClinicModal from './ChangeClinicModal';
import axios from 'axios';
interface Props {
  userName: string;
  api: any;
  modalService: any;
  userAuthenticationService: any;
  servicesManager: any;
  checked: boolean;
}

const AgencyDashboard: React.FC<Props> = ({
  userName,
  api,
  userAuthenticationService,
  modalService,
  servicesManager,
  checked,
}) => {
  const [agencyData, setAgencyData] = useState([]);
  const [errorStudiesCount, setErrorStudiesCount] = useState<number | null>(0);
  const [currentAgencyData, setCurrentAgencyData] = useState('');

  const getData = async () => {
    const agencyData = await api.users.getMe();
    const draftCount = await api.study.getMissingPhysicianCount();
    setErrorStudiesCount(draftCount || 0);
    const matchingAgency = (await agencyData)
      ? agencyData.crossBorderAgencies.find(
          agency => agency.id === agencyData.currentCrossBorderAgency
        )
      : '';
    setAgencyData(agencyData);
    setCurrentAgencyData(matchingAgency.name);
  };
  useEffect(() => {
    getData();
  }, [currentAgencyData]);

  return (
    <div
      className={`flex items-center ${
        checked ? 'justify-start' : 'justify-center'
      } align-middle content-center	self-center	justify-self-center	flex-1 mt-[6em] mx-2`}
    >
      <div className="grid justify-items-center">
        <div className="mb-[5em]">
          <DashBoardTitleBox
            title={`Welcome ${userName}`}
            subTitle={
              currentAgencyData
                ? `Your role is cross-border healthcare agency representative for CBHA ${currentAgencyData}`
                : 'Loading...'
            }
          />
        </div>
        <div className="grid grid-cols-3 justify-items-stretch gap-x-[60px] gap-y-[50px]  w-[45.8em] max-w-[900px] mb-[4em]">
          <RectangleButton
            text={'Select CBHA'}
            onClick={() =>
              modalService.show({
                layout: checked,
                content: ChangeClinicModal,
                contentProps: {
                  clinics: agencyData?.crossBorderAgencies,
                  onSelectedClinic: (crossBorderAgenciesId: string) => {
                    userAuthenticationService.setCrossBorderAgencyId(
                      crossBorderAgenciesId
                    );
                    modalService.hide();
                  },
                },
                title: 'Select your Cross-Border Healthcare Agency',
              })
            }
          />
          <Link to={`/studies-missingPhysician`}>
            <RectangleButton
              text={`${errorStudiesCount?.count ||
                0} studies w/o requesting Physician`}
            />
          </Link>
          <Link to={`/licensesList`}>
            <RectangleButton text={'Review your licenses'} />
          </Link>
          <Link to={`/studies`}>
            <RectangleButton text={'All studies'} />
          </Link>
          <RectangleButton
            onClick={() =>
              modalService.show({
                layout: checked,
                layoutWidth: checked,
                content: RecommendCarianModal,
                customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
                contentProps: {
                  servicesManager,
                  currentAgencyData,
                  onClose: () => {
                    modalService.hide();
                  },
                },
              })
            }
            text={'Recommend Carian to a colleague'}
          />
          <RectangleButton text={'Participate improve Carian'} />
        </div>
      </div>
    </div>
  );
};

export default AgencyDashboard;
