/**
 * DICOM Tags used by the application. Source:
 * {@link https://www.dicomlibrary.com/dicom/dicom-tags/ the DICOM Library}.
 */
export enum DicomTags {
  /** Patient ID */
  PATIENT_ID = 'x00100020',
  /** Patient's Name */
  PATIENT_NAME = 'x00100010',

  /** Institution Name */
  CLINIC = 'x00080080',

  /** Study Instance UID */
  STUDY_ID = 'x0020000d',
  /** Study Date */
  STUDY_DATE = 'x00080020',
  /** Study Time */
  STUDY_TIME = 'x00080030',
  /** Study Description */
  STUDY_DESCRIPTION = 'x00081030',

  /** Series Instance UID */
  SERIES_ID = 'x0020000e',
  /** Series Description */
  SERIES_DESCRIPTION = 'x0008103e',
  /** Series Number */
  SERIES_NUMBER = 'x00200011',
  /** Number of Series Related Instances */
  SERIES_INSTANCES = 'x00201209',
  /** Series Modality */
  SERIES_MODALITY = 'x00080060',
}
