import { ApisauceInstance, create } from 'apisauce';

import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import { DiagnosisApi } from './diagnosis';
import { OneTimeAccessApi } from './oneTimeAccess';
import { DiagnosisDictionaryApi } from './diagnosisDictionary';
import { LicensesApi } from './licenses';
import { StudiesApi } from './studies';
import { UsersApi } from './users';
import { CommunicationsApi } from './communications';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  token: string | null;

  clinicId: string | null;

  crossBorderAgencyId: string | null;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.token = null;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup(): void {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
    });
  }

  removeToken(): void {
    this.token = null;
    this.apisauce.setHeaders({
      Accept: 'application/json',
    });
    this.apisauce.deleteHeader('Authorization');
  }

  setToken(token: string): void {
    if (token === '') return;
    this.token = token;
    this.apisauce.setHeaders({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    });
  }
  setClinic(clinic: string): void {
    if (clinic === '') return;
    this.clinicId = clinic;
    this.apisauce.setHeaders({
      ...this.apisauce.headers,
      clinicId: `${clinic}`,
    });
  }

  setCrossBorderAgencyId(crossBorderAgencyId: string): void {
    if (!crossBorderAgencyId) return;
    this.crossBorderAgencyId = crossBorderAgencyId;
    this.apisauce.setHeaders({
      ...this.apisauce.headers,
      crossBorderAgencyId: `${crossBorderAgencyId}`,
    });
  }
}

const baseApi = new Api();

baseApi.setup();
const api = {
  api: baseApi,
  diagnosisDictionary: new DiagnosisDictionaryApi(baseApi),
  study: new StudiesApi(baseApi),
  diagnosis: new DiagnosisApi(baseApi),
  users: new UsersApi(baseApi),
  licenses: new LicensesApi(baseApi),
  communications: new CommunicationsApi(baseApi),
  oneTimeAccess: new OneTimeAccessApi(baseApi),
};

const responseMonitor = (response: any) => {
  if (response.status === 401) {
    api.api.removeToken();
    if (
      response.data.detail &&
      response.data.detail === 'Invalid or Inactive Token.'
    ) {
      console.log('Invalid Token');
    }
    if (response.data.detail && response.data.detail === 'Token has expired.') {
      console.log('Token Expired');
    }
    return;
  }
  if (response.status === 403) {
    return;
  }
  if (response.status === 404) {
    return;
  }
  if (response.status >= 500) {
    return;
  }
};

api.api.apisauce.addMonitor(responseMonitor);

export default api;
