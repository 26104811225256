import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RectangleButton, DashBoardTitleBox } from '@ohif/ui';

interface Props {
  userName: string;
  api: any;
  modalService: any;
  userAuthenticationService: any;
  servicesManager: any;
  checked: boolean;
}

const PatientDashboard: React.FC<Props> = ({
  userName,
  api,
  userAuthenticationService,
  modalService,
  servicesManager,
  checked,
}) => {
  const [errorStudiesCount, setErrorStudiesCount] = useState<number | null>(0);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const draftCount = await api.study.getMissingPhysicianCount();
    setErrorStudiesCount(draftCount || 0);
  };
  return (
    <div
      className={`flex items-center ${
        checked ? 'justify-start' : 'justify-center'
      } align-middle content-center	self-center	justify-self-center	flex-1 mt-[6em] mx-2`}
    >
      <div className="grid justify-items-center">
        <div className="mb-[5em]">
          <DashBoardTitleBox
            title={`Welcome ${userName}`}
            subTitle={'Patient'}
          />
        </div>
        <div className="grid justify-items-stretch grid-cols-3 gap-x-[60px] gap-y-[50px]  w-[45.8em] max-w-[900px] mb-[4em]">
          <Link to={`/upload-study`}>
            <RectangleButton text={'Upload study'} />
          </Link>
          <Link to={`/patient-studies`}>
            <RectangleButton text={'Study list'} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PatientDashboard;
