const name = 'UserAuthenticationService';

const publicAPI = {
  name,
  getState: _getState,
  setUser: _setUser,
  getUser: _getUser,
  getToken: _getToken,
  setToken: _setToken,
  getClinic: _getClinic,
  setClinic: _setClinic,
  setCrossBorderAgencyId: _setCrossBorderAgencyId,
  getCrossBorderAgencyId: _getCrossBorderAgencyId,
  getTokenData: _getTokenData,
  getAuthorizationHeader: _getAuthorizationHeader,
  handleUnauthenticated: _handleUnauthenticated,
  setServiceImplementation,
  reset: _reset,
  set: _set,
  rehydrateSession: _rehydrateSession,
};

const parseJwt = token => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const serviceImplementation = {
  _getState: () => console.warn('getState() NOT IMPLEMENTED'),
  _setUser: () => console.warn('_setUser() NOT IMPLEMENTED'),
  _getUser: () => console.warn('_getUser() NOT IMPLEMENTED'),
  _getToken: () => console.warn('_getToken() NOT IMPLEMENTED'),
  _getClinic: () => console.warn('_getClinic() NOT IMPLEMENTED'),
  _setToken: token => console.warn('_setToken() NOT IMPLEMENTED'),
  _setClinic: () => console.warn('_setClinic() NOT IMPLEMENTED'),
  _getAuthorizationHeader: () => { },
  _handleUnauthenticated: () =>
    console.warn('_handleUnauthenticated() NOT IMPLEMENTED'),
  _reset: () => console.warn('reset() NOT IMPLEMENTED'),
  _set: () => console.warn('set() NOT IMPLEMENTED'),
  _rehydrateSession: () => console.warn('rehydrateSession() NOT IMPLEMENTED'),
};

function _getState() {
  return serviceImplementation._getState();
}

function _setUser(user) {
  return serviceImplementation._setUser(user);
}

function _getUser() {
  return serviceImplementation._getUser();
}

function _setToken(token) {
  return serviceImplementation._setToken(token);
}

function _getToken() {
  return serviceImplementation._getToken();
}

function _getTokenData() {
  return parseJwt(serviceImplementation._getToken());
}

function _getClinic() {
  return serviceImplementation._getClinic();
}

function _setClinic(clinic) {
  return serviceImplementation._setClinic(clinic);
}
function _getCrossBorderAgencyId() {
  return serviceImplementation._getCrossBorderAgencyId();
}
function _setCrossBorderAgencyId(agency) {
  return serviceImplementation._setCrossBorderAgencyId(agency);
}

function _getAuthorizationHeader() {
  return serviceImplementation._getAuthorizationHeader();
}

function _handleUnauthenticated() {
  return serviceImplementation._handleUnauthenticated();
}

function _set(state) {
  return serviceImplementation._set(state);
}

function _reset() {
  return serviceImplementation._reset({});
}

function _rehydrateSession() {
  return serviceImplementation._rehydrateSession();
}

function setServiceImplementation({
  getState: getStateImplementation,
  setUser: setUserImplementation,
  getUser: getUserImplementation,
  getToken: getTokenImplementation,
  setToken: setTokenImplementation,
  getClinic: getClinicImplementation,
  setClinic: setClinicImplementation,
  getCrossBorderAgencyId: getCrossBorderAgencyIdImplementation,
  setCrossBorderAgencyId: setCrossBorderAgencyIdImplementation,
  getAuthorizationHeader: getAuthorizationHeaderImplementation,
  handleUnauthenticated: handleUnauthenticatedImplementation,
  reset: resetImplementation,
  set: setImplementation,
  rehydrateSession: rehydrateSessionImplementation,
}) {
  if (getStateImplementation) {
    serviceImplementation._getState = getStateImplementation;
  }
  if (setUserImplementation) {
    serviceImplementation._setUser = setUserImplementation;
  }
  if (getUserImplementation) {
    serviceImplementation._getUser = getUserImplementation;
  }
  if (setTokenImplementation) {
    serviceImplementation._setToken = setTokenImplementation;
  }
  if (getTokenImplementation) {
    serviceImplementation._getToken = getTokenImplementation;
  }
  if (getClinicImplementation) {
    serviceImplementation._getClinic = getClinicImplementation;
  }
  if (setClinicImplementation) {
    serviceImplementation._setClinic = setClinicImplementation;
  }
  if (getCrossBorderAgencyIdImplementation) {
    serviceImplementation._getCrossBorderAgencyId = getCrossBorderAgencyIdImplementation;
  }
  if (setCrossBorderAgencyIdImplementation) {
    serviceImplementation._setCrossBorderAgencyId = setCrossBorderAgencyIdImplementation;
  }
  if (getAuthorizationHeaderImplementation) {
    serviceImplementation._getAuthorizationHeader = getAuthorizationHeaderImplementation;
  }
  if (handleUnauthenticatedImplementation) {
    serviceImplementation._handleUnauthenticated = handleUnauthenticatedImplementation;
  }
  if (resetImplementation) {
    serviceImplementation._reset = resetImplementation;
  }
  if (setImplementation) {
    serviceImplementation._set = setImplementation;
  }
  if (rehydrateSessionImplementation) {
    serviceImplementation._rehydrateSession = rehydrateSessionImplementation;
  }
}

export default {
  name,
  create: ({ configuration = {} }) => {
    return publicAPI;
  },
};
