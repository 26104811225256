import React from 'react';
import PropTypes from 'prop-types';
import { DiagnosisList } from '@ohif/ui';
export default function PanelDiagnosis({ servicesManager, commandsManager }) {
  const {
    CarianApiService,
    UserAuthenticationService,
    UIModalService,
  } = servicesManager.services;

  const tokenData = UserAuthenticationService.getTokenData();
  const userRole = tokenData.roleId
    ? tokenData.roleId
    : tokenData.userData.roleId;
  if (userRole == 7) {
    CarianApiService.api.setCrossBorderAgencyId(
      UserAuthenticationService.getCrossBorderAgencyId()
    );
  } else {
    CarianApiService.api.setClinic(UserAuthenticationService.getClinic());
  }
  CarianApiService.api.setToken(UserAuthenticationService.getToken());

  return (
    <div className="flex flex-col justify-between mt-1">
      <DiagnosisList
        modalService={UIModalService}
        UserAuthenticationService={UserAuthenticationService}
        api={CarianApiService}
        dictionaryApi={CarianApiService}
        servicesManager={servicesManager}
      />
    </div>
  );
}

PanelDiagnosis.propTypes = {
  commandsManager: PropTypes.shape({
    runCommand: PropTypes.func.isRequired,
  }),
  servicesManager: PropTypes.shape({
    services: PropTypes.shape({
      SegmentationService: PropTypes.shape({
        getSegmentation: PropTypes.func.isRequired,
        getSegmentations: PropTypes.func.isRequired,
        toggleSegmentationVisibility: PropTypes.func.isRequired,
        subscribe: PropTypes.func.isRequired,
        EVENTS: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
