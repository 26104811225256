import { Button, Icon } from '@ohif/ui';
import React, { useState } from 'react';

type File = {
  patientName?: string;
  patientDocumentNumber?: string;
  studyId?: string;
  studyDate?: string;
  studyDescription?: string;
  clinicDicomId?: string;
  seriesDicomId?: string;
  seriesNumber?: string;
  seriesDescription?: string;
  seriesInstances?: string;
  seriesModality?: string;
};

interface Props {
  file: File;
  fileName?: string;
  openFile: boolean;
  handleRemoveFile: (file: File) => void;
}

const FileCard: React.FC<Props> = ({
  file,
  fileName,
  openFile,
  handleRemoveFile,
}) => {
  const [viewInfo, setViewInfo] = useState(openFile);

  return (
    <div>
      <h2 onClick={() => setViewInfo(!viewInfo)}>
        <button
          type="button"
          className="flex bg-secondary-dark items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
          data-accordion-target="#accordion-color-body-1"
          aria-expanded="true"
          aria-controls="accordion-color-body-1"
        >
          <span>FileName: {fileName}</span>
          <svg
            data-accordion-icon
            className="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      {viewInfo && (
        <div className="dark:bg-gray-900">
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Name: {file.patientName ?? ''}
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Document Number: {file.patientDocumentNumber ?? ''}
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Clinic: {file.clinicDicomId ?? ''}
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Study ID: {file.studyId ?? ''}
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Study Description: {file.studyDescription ?? ''}
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Study Date: {file.studyDate ?? ''}
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Image Description: {file.seriesDescription ?? ''}
            </p>
          </div>
          <div className="w-full flex justify-end p-5 ">
            <Button
              className="text-white bg-secondary-dark"
              onClick={() => handleRemoveFile(file)}
            >
              <p className="text-white mr-2">Remove</p>
              <Icon className="text-primary-light" name="old-trash" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileCard;
