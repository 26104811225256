import { Button, Typography, useSessionConfiguration } from '@ohif/ui';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import dicom from 'dicom-parser';
import { DicomTags } from './DicomTags';
import FileCard from './FileCard';
import RejectModal from './RejectModal';
import moment from 'moment';
import UploadModal from './UploadModal';

interface Props {
  servicesManager: any;
  apiService: any;
}

const DropZone: React.FC<Props> = ({ servicesManager, apiService}) => {
  const { UIModalService } = servicesManager.services;
  const [{ layout: checked }, api: apiLayout] = useSessionConfiguration();

  const [files, setFiles] = useState([]);
  const processStudyDate = (date?: string, time?: string) =>
    date
      ? time
        ? moment(`${date} ${time}`).format('YYYY/MM/DD HH:mm:ss')
        : moment(date).format('YYYY/MM/DD')
      : undefined;

  const processDicomMetadata = (
    dicomData: ArrayBuffer
  ): Record<string, string | number> => {
    const uint8Array = new Uint8Array(dicomData);
    let metadata;
    try {
      metadata = dicom.parseDicom(uint8Array);
    } catch (error) {
      UIModalService.show({
        content: RejectModal,
        contentProps: {
          onClose: () => {
            UIModalService.hide();
          },
        },
      });
    }

    return {
      patientName: metadata.string(DicomTags.PATIENT_NAME),
      patientDocumentNumber: metadata.string(DicomTags.PATIENT_ID),
      studyId: metadata.string(DicomTags.STUDY_ID),
      studyDate: processStudyDate(
        metadata.string(DicomTags.STUDY_DATE),
        metadata.string(DicomTags.STUDY_TIME)
      ),
      studyDescription: metadata.string(DicomTags.STUDY_DESCRIPTION),
      clinicDicomId: metadata.string(DicomTags.CLINIC),
      seriesDicomId: metadata.string(DicomTags.SERIES_ID),
      seriesNumber: metadata.string(DicomTags.SERIES_NUMBER),
      seriesDescription: metadata.string(DicomTags.SERIES_DESCRIPTION),
      seriesInstances: +(metadata.string(DicomTags.SERIES_INSTANCES) ?? 1),
      seriesModality: metadata.string(DicomTags.SERIES_MODALITY),
    };
  };

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: any) => {
    if (acceptedFiles.length) {
      acceptedFiles.forEach(file => {
        const reader = new FileReader();

        reader.onload = event => {
          if (event.target?.result) {
            const arrayBuffer = event.target.result;
            const metadata = processDicomMetadata(arrayBuffer);
            const fileWithPreview = Object.assign(file, {
              metadata: metadata,
            });

            setFiles(previousFiles => [...previousFiles, fileWithPreview]);
          }
        };

        reader.readAsArrayBuffer(file);
      });
    }

    if (fileRejections.length) {
      // Handle file rejections (non-.dcm files) if needed
      UIModalService.show({
        layout: checked,
        content: RejectModal,
        contentProps: {
          onClose: () => {
            UIModalService.hide();
          },
        },
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.dcm', // Accept only .dcm files
  });

  const handleRemoveFile = file => {
    setFiles(previousFiles =>
      previousFiles.filter(previousFile => previousFile.metadata !== file)
    );
  };

  return (
    <form className="text-white">
      <div
        {...getRootProps()}
        className="bg-secondary-dark p-20 cursor-pointer flex mt-5 justify-center border-2 rounded-lg border-primary-light"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag and drop some files here, or click to select files</p>
        )}
      </div>

      <div className="mt-5">
        <Typography variant="h4" className={'text-primary-light'}>
          Your files
        </Typography>
        <div className="mt-3 mb-6">
          {files.length < 1 ? (
            <div className="w-[100%] border-2 border-white rounded-lg text-center p-10 ">
              <Typography variant="h6" className={'text-white'}>
                No studies uploaded
              </Typography>
            </div>
          ) : (
            <div className="w-full border overflow-hidden border-gray-200 rounded-xl">
              {files.map(file => (
                <FileCard
                  openFile={file === files[0] ? true : false}
                  file={file.metadata}
                  fileName={file.name}
                  key={file.metadata.studyId}
                  handleRemoveFile={handleRemoveFile}
                />
              ))}
            </div>
          )}
          <div className="w-full flex justify-end mt-4">
            {files.length === 0 ? (
              <Button
                className="text-[18px] bg-common-light opacity-50 hover:bg-common-light hover:opacity-50">
              <Typography className="text-white text-[15px]" variant="body">
                Finalize Upload
              </Typography>
            </Button>)
            : (
            <Button
              className={`text-[18px] ${files.length === 0 ? 'bg-common-light opacity-50 hover:bg-common-light hover:opacity-50' : 'bg-secondary-dark'} `}
              onClick={() =>
                UIModalService.show({
                  layout: checked,
                  content: UploadModal,
                  contentProps: {
                    apiService,
                    onClose: () => {
                      UIModalService.hide();
                    },
                    files,
                  },
                })
              }
            >
              <Typography className="text-white text-[15px]" variant="body">
                Finalize Upload
              </Typography>
            </Button>)}

          </div>
        </div>
      </div>
    </form>
  );
};

export default DropZone;
